
<div id="fb-root"></div>
<script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0&appId=775663816577724&autoLogAppEvents=1" nonce="pSbLapWZ"></script>
<div class="search-list" fxLayout="row wrap" fxLayoutGap="4px grid" style="width: 100%;">
  <div class="card" fxFlex="25%" fxFlex.md="33%" fxFlex.sm="50%" fxFlex.xs="100%" *ngFor="let video of videos; let i = index; trackBy: identify; ">
      <div id="video{{i}}" class="card-content" [ngClass]="{'flip': isSelected[i]}">
        <mat-card fxLayout="column">
          <div class="thumbnail">
            <img mat-card-image [src]="video.thumbnail" (click)="!isSelected[i]? playVideo(video.videoId): null">
            <img mat-card-image class="play-button" (click)="!isSelected[i]? playVideo(video.videoId): null" src="../../assets/images/play-button2.png">
          </div>
          <div class="title" [innerHTML]="video.title"></div>
          <div class="buttons" fxLayout="row" fxLayoutAlign="space-between center">
            <div>{{video.views | number:'1.0':'en-US' }} views</div>
            <button mat-button>
              <mat-icon aria-label="Share" (click)='!isSelected[i]?  onSelect(i,true): null'>share</mat-icon>
            </button> 
            <button mat-button>
              <mat-icon aria-label="Description" (click)='!isSelected[i]?  onSelect(i,false): null'>flip</mat-icon>
            </button>
          </div>
        </mat-card>
        <mat-card class="back" fxLayout="column" fxLayoutAlign="space-between none">
          <!-- Description -->
          <div class="description" [innerHTML]="video.description" *ngIf="!showLink[i]"></div>     

          <!-- Share Link -->
          <share-links [videoId]="video.videoId" [videoTitle]="video.title" [index]="i" *ngIf="showLink[i]"></share-links>

          <!-- Video Thumbnail -->
          <div fxLayout="row" fxLayoutAlign="center center" *ngIf="showLink[i]">
            <img class="preview-img" [src]="video.thumbnail">
          </div>
          
          <!-- Buttons -->
          <div class="footer" fxLayout="column" fxLayoutAlign="end none">
            <div class="buttons" fxLayout="row" fxLayoutAlign="space-between center">
              <button mat-button (click)='playVideo(video.videoId)'>
                <span>PLAY</span>
                <mat-icon aria-label="Play">play_arrow</mat-icon>
              </button>
              <button mat-button>
                <mat-icon aria-label="Back" (click)='onFlipBack(i);'>undo</mat-icon>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
  </div>
</div>