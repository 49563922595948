<div id="top">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav-content class="mat-content">
      <div #navbar class="moveHeader">
        <mat-toolbar class="tbar">
          <!-- <color="primary" > -->
          <!-- First Row -->
          <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div [routerLink]="['/']" fxLayout="row" fxLayoutAlign="center center">
                <img src="../../assets/images/logo3.png" width="180px" style="padding-right: 8px;">
              </div>
            </div>
            <div class="search" fxLayout="row" fxLayoutAlign="center center"
              style="background-color:#FAFAFA; width:50%">
              <input type="text" placeholder="Search..." [value]="searchValue" name="search"
                (keyup.enter)="doSearch(search.value)" autocomplete="off" #search
                style="width:100%;height:24px;border:none;padding-left:5px;background-color: #FAFAFA;">
              <mat-icon style="color:rgb(0, 0, 0, 0.87);border:none;background-color: #FAFAFA;cursor: pointer;" aria-hidden="false"
                aria-label="Example home icon" (click)="doSearch(search.value)">search</mat-icon>
            </div>
          </mat-toolbar-row>
          <!-- Second Row -->
          <mat-toolbar-row class="row2" fxLayout="row">
            <button mat-icon-button aria-label="home" [routerLink]="['/']">
              <mat-icon>home</mat-icon>
            </button>
            <button aria-label="lcars" style="cursor:pointer; border-radius: 26px; background-color: #f70;color: #000;" [routerLink]="['/lcars']">
              LCARS
            </button>
            <span fxFlex></span>    
            <!-- <div class="fb-share-button" data-href="https://trektube.com" data-layout="button" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftrektube.com&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Share</a></div> -->
            <img src="../../assets/images/youtube-ll.png" width="200px">
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>