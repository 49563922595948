<div>
    <div *ngIf="inputTouched && !videos.length" class="ui four wide column centered grid" style="margin: 3rem;">
        <div class="ui raised aligned segment red warning message">
            <i class="warning icon"></i>
            <span style="margin: 0 auto;">No Video Found</span>
        </div>
    </div>
    <app-search-list [videos]="videos" [isSelected]="isSelected"></app-search-list>
    <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center">
        <mat-spinner class="spinner"></mat-spinner>
    </div>
    <div *ngIf="!loading && moreVideos && videos.length" fxLayout="row" fxLayoutAlign="center" >
        <div class="load-more" (click)="loadMore()" >&nbsp;&nbsp;&nbsp;Load More Videos&nbsp;&nbsp;&nbsp;</div>
    </div>
</div>
