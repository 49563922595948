<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start" fxLayoutAlign.gt-sm="center start">
    <!-- Column 1 -->
    <div fxLayout="column" fxFlex="100%" fxFlex.gt-sm="65%" style="position:relative;overflow:hidden;">
        <div id="player"></div>
        <div *ngIf="banner" fxLayoutAlign="center"
            style="align-items:center;width:100%;height:100%;position:absolute;background-color:black">
            <img [src]="'https://i.ytimg.com/vi/'+videoId+'/hqdefault.jpg'" style="width:100%;position:absolute;">
        </div>
    </div>
    <!-- Coulum 2 -->
    <div fxLayout="column" fxLayoutAlign="start center" style="margin:5px" [ngStyle.gt-sm]="{'margin': 'auto' }">
        <div class="button" id="bottom-left" (click)="togglePlay ? stopVideo():playVideo()">
            <a>{{togglePlay ?'STOP':'PLAY'}}</a>
        </div>
        <div *ngIf="togglePlay" class="button" (click)="togglePause ? playVideo():pauseVideo()">
            <a>{{togglePause ?'CONTINUE':'PAUSE'}}</a>
        </div>
        <input *ngIf="togglePlay" #slide type="range" min="0" max="100" [value]="setPosition() || 0"
            (input)="seekPosition(slide.value)" style="margin-top:30px;width: 200px;">
    </div>
</div>
