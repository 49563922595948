import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})

export class VideoPlayerComponent implements OnInit {
  videoId:string = "";
  videoUrl: string;
  videoSrc;
  hide:string = '';
  moveTimer: any;
  public YT: any;
  public player: any;
  public reframed: Boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<VideoPlayerComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any ) {
      if(data && data.videoId){
        this.videoId = data.videoId;

        // for iframe in html
        //this.videoUrl = "https://www.youtube.com/embed/?enablejsapi=1&origin=https://"+window.location.host+"&autoplay=1&modestbranding=1&controls=1&disablekb=1&rel=0&showinfo=0&fs=1&playsinline=1&playlist=5fi8iAxctrE,"+ this.videoId;

        this.videoUrl = "https://www.youtube.com/embed/?playlist=5fi8iAxctrE,"+ this.videoId;
        this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
      }

    }

  ngOnInit() {
    if (window['YT']) {
      console.log("in onInit StartVideo")
      this.startVideo();
      return;
    }

    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);

    window['onYouTubeIframeAPIReady'] = () => this.startVideo();

    this.startTimeout();
  }

  startVideo() {
    console.log("Starting Video...")
    this.reframed = false;
    this.player = new window['YT'].Player('player', {
      height: "100%",
      width: "100%",
      videoId: this.videoId,
      playerVars: {
        enablejsapi: 1,
        origin: "https://" + window.location.host,
        autoplay: 1,
        modestbranding: 1,
        controls: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 1, 
        playsinline: 1,
        //start: (new Date().getMinutes()) * 60
        // playlist:"5fi8iAxctrE," + this.videoId
      },
      events: {
        // 'onStateChange': this.onPlayerStateChange.bind(this),
        //'onError': this.onPlayerError.bind(this),
        'onReady': this.onPlayerReady.bind(this),
      }
    });
  }

  onPlayerReady(event) {
    event.target.playVideo();
  }

  // onPlayerStateChange(event) {
  //   switch (event.data) {
  //     case window['YT'].PlayerState.PLAYING:
  //       if (this.cleanTime() == 0) {
  //         console.log('started ' + this.cleanTime());
  //       } else {
  //         console.log('playing ' + this.cleanTime())
  //       };
  //       break;
  //     case window['YT'].PlayerState.PAUSED:
  //       if (this.player.getDuration() - this.player.getCurrentTime() != 0) {
  //         console.log('paused' + ' @ ' + this.cleanTime());
  //       };
  //       break;
  //     case window['YT'].PlayerState.ENDED:
  //       console.log('ended ');
  //       break;
  //   }
  // }

  // cleanTime() {
  //   return Math.round(this.player.getCurrentTime())
  // }

  // onPlayerError(event) {
  //   console.log(event)
  //   event.target.playVideo();
  //   switch (event.data) {
  //     case 2:
  //       console.log('' + this.videoId)
  //       break;
  //     case 100:
  //       break;
  //     case 101 || 150:
  //       console.log("101-150")
  //       event.target.playVideo();
  //       break;
  //   }
  // }

  holdView(){
    clearTimeout(this.moveTimer);
    this.hide = '';
  }

  startView(){
    this.hide = '';
    this.startTimeout();
  }

  startTimeout(){
    this.moveTimer = setTimeout(()=>{
      this.hide = 'none';
    }, 1000);
  }
}
