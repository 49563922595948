import { Component, Input, NgZone, OnInit } from '@angular/core';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['../../layouts/lcars/lcars.component.css']
})
export class PlayerComponent implements OnInit {
  public YT: any;
  public player: any;
  public reframed: Boolean = false;
  togglePlay = false;
  togglePause = false;
  banner = true;
  sliderUpdate: any;

  @Input() videoId: string = '';

  constructor(private ngZone: NgZone) { }

  ngOnInit(): void {
    if (window['YT']) {
      this.startVideo();
      return;
    }

    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);

    window['onYouTubeIframeAPIReady'] = () => this.startVideo();

  }

  startVideo() {
    this.reframed = false;
    this.player = new window['YT'].Player('player', {
      // height: "100%",
      width: "100%",
      playerVars: {
        autoplay: 0,
        modestbranding: 1,
        controls: 0,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 0,
        playsinline: 1,
        playlist:'5fi8iAxctrE,' + this.videoId
      },
      events: {
        'onStateChange': (event) => this.ngZone.run(() => this.onPlayerStateChange(event)),
        //'onError': (event) => this.ngZone.run(() => this.onPlayerError(event)),
        //'onReady': (event) => this.ngZone.run(() => this.onPlayerReady(event))
      }
    });

  }

  updateSliderTimer() {
    this.sliderUpdate = setInterval(function () { }, 1000);
  }

  setPosition(){
    return (this.cleanTime() / this.cleanDuration()) * 100;
  }

  seekPosition(val){
    this.player.seekTo(this.cleanDuration() * (val / 100));
  }

  cleanTime(): number {
    return Math.round(this.player.getCurrentTime());
  };

  cleanDuration(): number {
    return Math.round(this.player.getDuration());
  };


  playVideo(){
    this.player.playVideo();
    this.banner = false;
    this.togglePlay = true;
    this.togglePause = false;
  }

  stopVideo(){
    this.player.stopVideo();
    this.banner = true;
    this.togglePlay = false;
    this.togglePause = false;
  }

  pauseVideo(){
    this.player.pauseVideo();
    this.togglePause = true;
  }

  onPlayerStateChange(event){
    if(event.data == 0){
      this.togglePlay = false;
      this.banner = true;
      clearInterval(this.sliderUpdate);
    } 
    if(event.data == 1){
      this.updateSliderTimer();
    }
  }


}
