<div fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="row" class="lcars-text-bar" style="margin:20 0 40px 0;">
        <span>Featured Video</span>
    </div>

    <app-player [videoId]="'0eSMstOIb-U'" ></app-player>

    <!-- <div fxLayout="row" fxLayoutAlign="center start">
        <div fxLayout="column" style="width:65%;position:relative;overflow:hidden;">
            <div id="player"></div>
            <div *ngIf="banner" fxLayoutAlign="center"
                style="width:100%;height:100%;position:absolute;background-color:black">
                <img [src]="'https://i.ytimg.com/vi/'+videoId+'/hqdefault.jpg'" style="height:100%;position:absolute;">
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start center" style="margin:20px">
            <div class="button" id="bottom-left" (click)="togglePlay ? stopVideo():playVideo()">
                <a>{{togglePlay ?'STOP':'PLAY'}}</a>
            </div>
            <div *ngIf="togglePlay" class="button" (click)="togglePause ? playVideo():pauseVideo()">
                <a>{{togglePause ?'CONTINUE':'PAUSE'}}</a>
            </div>
        </div>
    </div> -->

    <div fxLayout="row" class="lcars-text-bar" style="margin:40px 0 20px 0;">
        <span>Popular Videos</span>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center">
        <mat-card style="background-color:black" fxLayout="column" class="r-video-card">
            <a href="/lcars/player?v=a-dbI3zKxDI">
                <img src="https://i.ytimg.com/vi/a-dbI3zKxDI/hqdefault.jpg" width="200px">
            </a>
        </mat-card>
        <mat-card style="background-color:black" fxLayout="column" class="r-video-card">
            <a href="/lcars/player?v=yTQxF_TbVwE">
                <img src="https://i.ytimg.com/vi/yTQxF_TbVwE/hqdefault.jpg" width="200px">
            </a>
        </mat-card>
        <mat-card style="background-color:black" fxLayout="column" class="r-video-card">
            <a href="/lcars/player?v=l4TC5wl0IzE">
                <img src="https://i.ytimg.com/vi/l4TC5wl0IzE/hqdefault.jpg" width="200px">
            </a>
        </mat-card>
        <mat-card style="background-color:black" fxLayout="column" class="r-video-card">
            <a href="/lcars/player?v=SWyxZR69CI0">
                <img src="https://i.ytimg.com/vi/SWyxZR69CI0/hqdefault.jpg" width="200px">
            </a>
        </mat-card>
        <mat-card style="background-color:black" fxLayout="column" class="r-video-card">
            <a href="/lcars/player?v=ctvy3xz71OI"> 
                <img src="https://i.ytimg.com/vi/ctvy3xz71OI/hqdefault.jpg" width="200px">
            </a>
        </mat-card>
        <mat-card style="background-color:black" fxLayout="column" class="r-video-card">
            <a href="/lcars/player?v=nhDtUekNKEI">
                <img src="https://i.ytimg.com/vi/nhDtUekNKEI/hqdefault.jpg" width="200px">
            </a>
        </mat-card>
        <mat-card style="background-color:black" fxLayout="column" class="r-video-card">
            <a href="/lcars/player?v=KLCrAb_ESw8">
                <img src="https://i.ytimg.com/vi/KLCrAb_ESw8/hqdefault.jpg" width="200px">
            </a>
        </mat-card>
        <mat-card style="background-color:black" fxLayout="column" class="r-video-card">
            <a href="/lcars/player?v=tssvdg0e_qQ">
                <img src="https://i.ytimg.com/vi/tssvdg0e_qQ/hqdefault.jpg" width="200px">
            </a>
        </mat-card>
        <mat-card style="background-color:black" fxLayout="column" class="r-video-card">
            <a href="/lcars/player?v=3fmNHxz8Zcg">
                <img src="https://i.ytimg.com/vi/3fmNHxz8Zcg/hqdefault.jpg" width="200px">
            </a>
        </mat-card>
        <mat-card style="background-color:black" fxLayout="column" class="r-video-card">
            <a href="/lcars/player?v=oB5X9Ew760c">
                <img src="https://i.ytimg.com/vi/oB5X9Ew760c/hqdefault.jpg" width="200px">
            </a>
        </mat-card>
        <mat-card style="background-color:black" fxLayout="column" class="r-video-card">
            <a href="/lcars/player?v=XL4iCAB6MFo">
                <img src="https://i.ytimg.com/vi/XL4iCAB6MFo/hqdefault.jpg" width="200px">
            </a>
        </mat-card>
        <mat-card style="background-color:black" fxLayout="column" class="r-video-card">
            <a href="/lcars/player?v=vuMFDtpjA3M">
                <img src="https://i.ytimg.com/vi/vuMFDtpjA3M/hqdefault.jpg" width="200px">
            </a>
        </mat-card>
    </div>
</div>