import { Component, OnInit } from '@angular/core';
import { SearchService } from 'src/app/shared/services/search.service';
import { Video } from 'src/app/shared/models/search.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  inputTouched = false;
  loading = false;
  videos: Video[] = [];
  isSelected: boolean[] = [];
  query: string;
  videoOptions: string;
  sSub: Subscription;
  vSub: Subscription;
  refreshSub: Subscription;
  moreVideos: boolean= false;
  browserRefresh: boolean;
  currentChannel: string;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService) {
      this.route.queryParams.subscribe(queryParams => {
        this.query = queryParams['q'];
        this.videoOptions = queryParams['o'];
        if(this.query){
          // refresh search bar text
          this.searchService.setSearch(this.query, false);
          // do search
          this.doSearch(this.query, this.videoOptions);
        }
      });
  }

  ngOnInit(): void {
  }

  doSearch(search: string, videoOptions?: string) {
    if (videoOptions && !videoOptions.startsWith("PL")){
      this.currentChannel = videoOptions;
    } else {
      this.currentChannel = undefined;
    }
    this.loading = true;
    this.inputTouched = false;
    this.videos = [];
    this.isSelected = [];
    window.scroll(0,0);
    this.getVideos(search, videoOptions);
  }

  loadMore() {
    this.loading = true;
    this.getVideos("*next-page*", this.currentChannel);
  }

  getVideos(search: string, videoOptions?: string) {
    this.vSub = this.searchService.getVideos(search, videoOptions)
      .subscribe((items: any) => {
        this.moreVideos = !!this.searchService.nextPageToken;
        items.forEach(item => {
          let pushVideo = false;
          if (item.snippet.description.toLowerCase().includes('star trek') || 
              item.snippet.title.toLowerCase().includes('star trek')) {
              pushVideo = true;
          }
          if (pushVideo) {
            this.videos.push(
              {
                title: item.snippet.title,
                videoId: item.id,
                videoUrl: `https://www.youtube.com/watch?v=${item.id.videoId}`,
                channelId: item.snippet.channelId,
                channelUrl: `https://www.youtube.com/channel/${item.snippet.channelId}`,
                channelTitle: item.snippet.channelTitle,
                description: item.snippet.description,
                publishedAt: new Date(item.snippet.publishedAt),
                thumbnail: item.snippet.thumbnails.high.url,
                views: item.statistics.viewCount
              }
            );
          }
        });
        this.inputTouched = true;
        this.loading = false;
      });
  }

  ngOnDestroy() {
    if (this.vSub) { this.vSub.unsubscribe(); };
  }

}
