import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { SearchService } from 'src/app/shared/services/search.service';
import { Video } from 'src/app/shared/models/search.interface';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  inputTouched = false;
  loading = false;
  videos: Video[] = [];
  query: string;
  flipToggle:boolean =false;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private searchService: SearchService) { 
      this.route.queryParams.subscribe(queryParams => {
        this.query = queryParams['v'];
        if(this.query){
          // play video
          this.playVideo(this.query);
        }
      });
  }

  ngOnInit(): void {
  }

  playVideo(id:string) {
    const dialogRef = this.dialog.open(VideoPlayerComponent,{
      panelClass: 'player-dialog',
      width: '100%',
      data: {videoId : id }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  doSearch(event, videoOptions?: string){
    this.searchService.setSearch(event.target.value || event.target.textContent, true, videoOptions);
  }

}
