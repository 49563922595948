<main-nav id="mainnav">
    <div class="container" fxLayout="column" fxLayoutAlign="space-between">
        <div id="video" class="content">
            <router-outlet></router-outlet>
        </div>
        <div class="footer">
            <div>
                <p>“Star Trek and all related marks, logos and characters are solely owned by CBS Studios Inc. Any fan
                    production shown on this site is not endorsed by, sponsored by, nor affiliated with CBS, Paramount
                    Pictures, or any other Star Trek franchise, and is a non-commercial fan-made film intended for
                    recreational use. No commercial exhibition or distribution is permitted. No alleged independent
                    rights will be asserted against CBS or Paramount Pictures.”</p>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between">
                <div>All video content provided by YouTube.com.</div>
                <!-- <div><img src="../../assets/images/youtube-ll.png" width="200px"></div> -->
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <div>Site Design by TrekTube.com, <wbr>&copy;&nbsp;2022 All Rights Reserved.</div>
                <div>Contact: info@trektube.com</div>
            </div>
        </div>
    </div>
</main-nav>