import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './layouts/main/main.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';

import { LcarsComponent } from './layouts/lcars/lcars.component';
import { LCARS_AboutComponent } from './lcars/about/about.component';
import { LCARS_HomeComponent } from './lcars/home/home.component';
import { LCARS_SearchComponent } from './lcars/search/search.component';
import { LCARS_PlayComponent } from './lcars/play/play.component';


const routes: Routes = [
  {
    path:'',
    component: MainComponent,
    children: [
      {
        path:'',
        component: HomeComponent
      },
      {
        path:'watch',
        component: HomeComponent
      },
      {
        path:'home',
        component: HomeComponent
      },
      {
        path:'search',
        component: SearchComponent
      },
    ]
  },
  {
    path:'lcars',
    component: LcarsComponent,
    children: [
      {
        path:'',
        component: LCARS_HomeComponent
      },
      {
        path:'watch',
        component: LCARS_HomeComponent
      },
      {
        path:'home',
        component: LCARS_HomeComponent
      },
      {
        path:'about',
        component: LCARS_AboutComponent
      },
      {
        path:'search',
        component: LCARS_SearchComponent
      },
      {
        path:'player',
        component: LCARS_PlayComponent
      },
    ]
  },
  {
    path:'**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
