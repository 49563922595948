<div class="link-info">
    <p>Sharing Links</p>
    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="padding:0 15px 0 15px;">
        <!-- Copy Link Button -->
        <div class="link"><img (click)="sendToClipboard('https://trektube.com/watch?v='+videoId)" src="../../assets/images/icons/link-30.png" width="30px" alt="copy link" title="copy link"></div>
                
        <!-- Facebook Button -->
        <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0&appId=775663816577724&autoLogAppEvents=1" nonce="6ltF3J8Z"></script>
        <div class="fb-share-button" [attr.data-href]="'https://trektube.com/watch?v='+videoId"  
            data-layout="button" data-size="small"><a target="_blank" 
            [href]="'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftrektube.com%2Fwatch%3Fv%3D'+videoId+'&amp;src=sdkpreparse'" 
            class="fb-xfbml-parse-ignore"><img src="../../assets/images/icons/facebook-30.png" width="30px" alt="facebook"
                    title="facebook"></a>
        </div>

        <!-- Twitter Button -->
        <div class="link">
            <a class="twitter-share-button" target="_blank" [href]="'https://twitter.com/intent/tweet?text=Trektube.com: '+videoTitle+'&amp;url=https%3A%2F%2Ftrektube.com/watch?v='+videoId">
                <img src="../../assets/images/icons/twitter-30.png" width="30px" alt="twitter" title="twitter">
            </a>
        </div>
    </div>  
</div>

