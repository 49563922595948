import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private API_URL = '/youtube-search';
  private API_URL2 = '/youtube-videos';
  private API_URL3 = '/youtube-playlist';
  private API_TOKEN = 'AIzaSyCLpRDvkbZH1JmVw2wj7sfagsOZAgGIyVE';

  _search = new BehaviorSubject<string>('');
  search$ = this._search.asObservable();

  public nextPageToken: string ="";
  public currentSearch: string ="";

  constructor(
    private http: HttpClient,
    private router: Router) {}

  setSearch(search:string, navigate:boolean, videoOptions?: string){
    this.nextPageToken ="";
    this.currentSearch = search;
    this._search.next(search);
    if(navigate){
      this.router.navigate(['/search'],{ skipLocationChange: false, queryParams: { q: search, o: videoOptions } });
    }
  }

  setLCARS_Search(search:string, navigate:boolean, videoOptions?: string){
    this.nextPageToken ="";
    this.currentSearch = search;
    this._search.next(search);
    if(navigate){
      this.router.navigate(['/lcars/search'],{ skipLocationChange: false, queryParams: { q: search, o: videoOptions } });
    }
  }

  getVideoDesc(videoId){
    return this.http.get<{title: string, description: string}>(`${this.API_URL2}?key=${this.API_TOKEN}&part=snippet&id=${videoId}&type=video&videoEmbeddable=true`)
      .pipe(
        map((response:any) => ({
          title: response.items[0].snippet.title,
          description: response.items[0].snippet.description}
        ))
      )
  }


  getVideos(search: string, videoOptions?: string): Observable <any> {
    if (search == "*next-page*") { search = this.currentSearch }
    else { this.currentSearch = search }
    let qtemp: string = search.split(' ').join(' +');
    let query = '+"Star+Trek" +' + qtemp;
    let url: string;

    if (videoOptions){
      // Get Videos from Playlist
      if (videoOptions.startsWith("PL")){
        url = `${this.API_URL3}?playlistId=${videoOptions}&key=${this.API_TOKEN}&pageToken=${this.nextPageToken}&part=contentDetails&type=video&videoEmbeddable=true&maxResults=20&order=relevance`;
      } else {
        // Get Videos from Channel
        url = `${this.API_URL}?channelId=${videoOptions}&key=${this.API_TOKEN}&pageToken=${this.nextPageToken}&part=id&type=video&videoEmbeddable=true&maxResults=20&order=date`;
      }   
    } else {
      // Get Videos from Search
      if (!videoOptions){
        url = `${this.API_URL}?q=${query}&key=${this.API_TOKEN}&pageToken=${this.nextPageToken}&part=id&type=video&videoEmbeddable=true&maxResults=20&order=relevance`;
      }
    }

    return this.http.get(url)
      .pipe(
        tap((res: any) => {
          this.nextPageToken = res.nextPageToken; 
        })
      )
      .pipe(
        map((response: any) => response.items.map(function(val) {
          if(videoOptions && videoOptions.startsWith("PL")){
            return val.contentDetails.videoId;
          } else {
            return val.id.videoId;
          }
            }).join(',')
        )
      )
      .pipe(
        switchMap(ids => this.http.get(`${this.API_URL2}?key=${this.API_TOKEN}&part=snippet,statistics&id=${ids}&type=video&videoEmbeddable=true&maxResults=20`))
      )
      .pipe(
        map((response: any) => response.items)
      )
  }
}