import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SearchService } from '../shared/services/search.service';
import { Observable } from 'rxjs';
import { map, shareReplay  } from 'rxjs/operators';

@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})

export class MainNavComponent {
  @ViewChild('navbar') navElement: ElementRef;
  @ViewChild('search') search: ElementRef;

  prevScrollPos = window.pageYOffset;
  navIsShowing: boolean;

  @HostListener('window:scroll', ['$event'])
  handleScroll(){
      const currentScrollPos = window.pageYOffset;
      if(currentScrollPos >= this.prevScrollPos){
          this.navIsShowing = false;
          // Hide Navbar
          if (currentScrollPos >= 100){
            this.navElement.nativeElement.style.transition = "all 0.3s ease-in-out";
            this.navElement.nativeElement.style.transform = "translateY(-100%)"; 
          } else {
            this.navElement.nativeElement.style.transition = "none";
            this.navElement.nativeElement.style.transform = "translateY(-" + currentScrollPos + "%)";
          }
      } else {
          // Show Navbar
          if (currentScrollPos >= 100){
            this.navElement.nativeElement.style.transition = "all 0.3s ease-in-out";
            this.navElement.nativeElement.style.transform = "translateY(0)";
            this.navIsShowing = true;
          }else {
            if (!this.navIsShowing){
              this.navElement.nativeElement.style.transition = "none";
              this.navElement.nativeElement.style.transform = "translateY(-" + currentScrollPos + "%)";
            }
          }
      }
      this.prevScrollPos = currentScrollPos;
  }

  searchValue:string = "";

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private searchService: SearchService,
    ) {
      this.searchService.search$.subscribe(
      (search:string) => { 
        if(search){
          this.searchValue = search;
        }
      }
    )}

    doSearch(val){
      this.search.nativeElement.blur();
      if(val){
        this.searchService.setSearch(val, true);
      }
    }

}
