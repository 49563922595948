import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HomeComponent } from './home/home.component';
import { SearchListComponent } from './search-list/search-list.component';

import { RequestCache } from './core/services/request-cache.service'
import { CacheInterceptor } from './core/services/cache-interceptor.service';
import { VideoPlayerModule } from './video-player/video-player.module';
import { SearchComponent } from './search/search.component';
import { environment } from 'src/environments/environment';
import { ShareLinksComponent } from './share-links/share-links.component';

import { MainComponent } from './layouts/main/main.component';

import { LCARS_AboutComponent } from './lcars/about/about.component';
import { LCARS_HomeComponent } from './lcars/home/home.component';
import { LcarsComponent } from './layouts/lcars/lcars.component';
import { LCARS_PlayComponent } from './lcars/play/play.component';
import { LCARS_SearchComponent } from './lcars/search/search.component';

import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSliderModule} from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { PlayerComponent } from './lcars/player/player.component';

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    HomeComponent,
    SearchListComponent,
    SearchComponent,
    ShareLinksComponent,
    LcarsComponent,
    MainComponent,
    LCARS_AboutComponent,
    LCARS_HomeComponent,
    LCARS_PlayComponent,
    LCARS_SearchComponent,
    PlayerComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    ScrollingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatChipsModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    FlexLayoutModule,
    MatCardModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    VideoPlayerModule,
    MatProgressBarModule,
    MatSliderModule,
    FormsModule
  ],
  exports: [],
  providers: [
    RequestCache,
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
