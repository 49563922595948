<div fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="row" class="lcars-text-bar" style="margin:20 0 40px 0;">
        <span>Video Player</span>
    </div>

    <app-player [videoId]="videoId" ></app-player>

    <div fxLayout="row" class="lcars-text-bar" style="margin:40px 0 20px 0;">
        <span>Video Details</span>
    </div>

    <div *ngIf="(videoDetails$ | async)as info">
        <h2>{{info.title}}</h2>
        <div style="height:300px;overflow-x:hidden; overflow-y:scroll;width:99%;padding-right:15px;">
            {{info.description}}
        </div>
    </div>
</div>