// search-list.component.ts

import { Component, OnInit, Input } from '@angular/core';
import { Video } from '../shared/models/search.interface';
import { MatDialog } from '@angular/material/dialog';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from 'src/app/shared/services/search.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.css']
})

export class SearchListComponent implements OnInit {
  flipped: boolean = false;
  query: string ="";
  play: string ="Play-";
  
  @Input() videos: Video[];
  @Input() isSelected: boolean[];
  showLink: boolean[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private searchService: SearchService) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#top').scrollIntoView();
    } catch (e) { }
  }

  status: boolean = false;
  clickEvent(){
      this.status = !this.status;       
  }

  onSelect(i:number, link:boolean = false){
    this.showLink[i] = link;
    this.isSelected[i] = !this.isSelected[i];
  }
  onFlipBack(i:number){
    this.isSelected[i] = !this.isSelected[i];
  }

  playVideo(id:string) { 
    const dialogRef = this.dialog.open(VideoPlayerComponent,{
      panelClass: 'player-dialog',
      width: '100%',
      data: {videoId : id }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  identify(index, item){
    return item.videoId; 
 }
 
}