<div class="video-container flex-column" (mouseover)="holdView()" (mouseout)="startView()">
    <button class="top-bar flex-exit-row roboto-bold button font20" mat-button (click)="dialogRef.close()"
        [ngStyle]="{'display': hide }">X</button>
    <div class="ytplayer"><div id="player"></div></div>
    <!-- <div class="ytplayer">
        <iframe id="ytplayer" type="text/html" width="100%" height="100%"
            [src]="videoSrc" frameborder="0">
        </iframe>
    </div> -->
</div>





<!-- (mouseover)="holdView()" (mousemove)="holdView()" (mouseout)="startView()" -->

<!-- <div class="top-bar flex-exit-row" [ngStyle]="{'display': hide }" (mousemove)="holdView()" (mouseout)="startView()">
    <button class="roboto-bold button font20" mat-button (click)="dialogRef.close()">X</button>
</div>

<div class="control-bar flex-column">
    <div class="control-bar flex-column" [ngStyle]="{'display': hide }" (mousemove)="holdView()" (mouseout)="startView()">
        <div class="flex-control-row" style="height: 10px;width:100px">
            mm
        </div>
        <div class="flex-control-row">
            <div style="display:flex;flex-direction: row;">
                <div>
                    <mat-icon (click)="playToggle('play')" [ngStyle]="{'display': togglePlay }">play_arrow</mat-icon>
                    <mat-icon (click)="playToggle('pause')" [ngStyle]="{'display': togglePause }">pause</mat-icon>
                </div>
                <div>
                    <mat-icon (click)="volumeOn()" [ngStyle]="{'display': volLevel == 0 || volOff == 0? '':'none' }">
                        volume_off</mat-icon>
                    <mat-icon (click)="volumeOff()"
                        [ngStyle]="{'display': volLevel >= 1 && volLevel <= 49 && volOff == 99? '':'none' }">volume_down
                    </mat-icon>
                    <mat-icon (click)="volumeOff()" [ngStyle]="{'display': volLevel >= 50 && volOff ==99? '':'none' }">
                        volume_up</mat-icon>
                </div>
                <div>
                    <mat-slider min="0" max="100" step="1" [(ngModel)]="volLevel"></mat-slider>
                </div>
            </div>

            <div>
                <mat-icon>picture_in_picture_alt</mat-icon>
                <mat-icon (click)="fullScreen(true)" [ngClass]="{'displayNone': fullscreen }">fullscreen</mat-icon>
                <mat-icon (click)="fullScreen(false)" [ngClass]="{'displayNone': !fullscreen }">fullscreen_exit
                </mat-icon>
            </div>
        </div>
    </div>

    <div class="overlay flex-column" [ngClass]="{'displayCover': cover }" (mouseout)="showBar($event)" (mousemove)="showBar($event)"></div>  -->

<!-- <object id="player" [data]="videoSrc" class="ytplayer" ></object>  -->
<!-- <div class="ytplayer"><div id="player"></div></div> 
</div> -->
































<!-- <section class="example-section">
    <mat-progress-bar
        class="example-margin"
        [color]="color"
        [mode]="mode"
        [value]="value"
        [bufferValue]="bufferValue">
    </mat-progress-bar>
</section> -->



<!-- ****************************************************************************************** -->

<!-- <div class="bar" [ngStyle]="{'display': hide }" fxLayout="row" style="background-color: green;height: 50px;" 
       (mouseout)="showBar($event)">
        <button class="button" mat-button (click)="dialogRef.close()">X</button>
    </div> -->

<!-- <div style="height:100%;width:100%;position:absolute;top:0;left:0;background-color:transparent;z-index: 20;" 
        (mousemove)="showBar($event)" (mouseout)="showBar($event)">&nbsp;</div> -->

<!-- <iframe 
        class="ytplayer"
        frameborder="0" allowfullscreen="1"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player"
        [src]="videoSrc"
        id="widget3">
    </iframe> -->

<!-- <object [data]="videoSrc" class="ytplayer" >
    </object> -->
<!-- <div class="controls">
        <div>
            <div class="button"> 
                <button class="pause" style="display: block;">
                </button> <button class="play" style="display: none;">
                </button> </div>
            <div>
                <div class="progressBar">
                    <div style="width: 22%;">
                    </div>
                </div>
            </div>
            <div class="quality"><select name="qualityLevel">
                    <option title="Change quality" value="hd720">720p</option>
                    <option title="Change quality" value="large" selected="">480p</option>
                    <option title="Change quality" value="medium">360p</option>
                    <option title="Change quality" value="small">240p</option>
                    <option title="Change quality" value="tiny">tiny</option>
                    <option title="Change quality" value="auto">auto</option>
                </select></div>
            <div class="timer">2:14 | 9:56</div>
            <div class="button"> <button class="mute">               
                </button> <button class="unmute">
                </button> </div>
            <div class="button"> <button class="enterFullscreen">
                </button> <button class="leaveFullscreen">
                </button> </div>
        </div>
    </div> -->





<!-- <div id="test" (mouseout)="changeStyle2($event)"  (mousemove)="changeStyle2($event)"
    [ngStyle]="{'background-color': bcolor }" >
    </div> -->