import { Component, Input, OnInit } from '@angular/core';

function updateClipboard(newClip) {
  navigator.clipboard.writeText(newClip).then(function() {
    /* clipboard successfully set */
    alert("Link copied to clipboard");
  }, function() {
    /* clipboard write failed */
    alert("error trying to copy link");
  });
}

@Component({
  selector: 'share-links',
  templateUrl: './share-links.component.html',
  styleUrls: ['./share-links.component.css']
})
export class ShareLinksComponent implements OnInit {

  @Input() videoId: string;
  @Input() videoTitle: string;
  @Input() index: number;

  dataHref: string;

  constructor() { }

  ngOnInit(): void {
    this.videoTitle = encodeURIComponent(this.videoTitle)
  }

  sendToClipboard(textLink){
    updateClipboard(textLink);
  }

}
