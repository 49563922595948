import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SearchService } from 'src/app/shared/services/search.service';

@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['../../layouts/lcars/lcars.component.css']
})
export class LCARS_PlayComponent implements OnInit {
  videoId: string;
  videoDetails$: Observable<{ title: '', description: '' }>;

  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(queryParams => {
      this.videoId = queryParams['v'];
    });
  }

  ngOnInit(): void {
    this.getVideoDesc();
  }

  getVideoDesc() {
    this.videoDetails$ = this.searchService.getVideoDesc(this.videoId);
  }

}
