import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SearchService } from 'src/app/shared/services/search.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../../layouts/lcars/lcars.component.css']
})

export class LCARS_HomeComponent implements OnInit {
  videoId = 'SAlGf5Wfe4g';
  videoDetails$: Observable<{ title: '', description: '' }>;

  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.getVideoDesc();
  }

  getVideoDesc() {
    this.videoDetails$ = this.searchService.getVideoDesc(this.videoId);
  }

}
