<div fxLayout="row" fxLayout.xs="column">
    <div fxFlex="70" fxLayout="column">
        <mat-card class="featured">
            <div class="title">FEATURED VIDEO</div>
        </mat-card>
        <mat-card fxLayout="column" class="video-card">
            <div class="thumbnail" (click)="playVideo('_KXU2Ob8gYY')">
                <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                <img src="https://i.ytimg.com/vi/_KXU2Ob8gYY/hqdefault.jpg" width="100%">
                <div style="position:relative;color:antiquewhite; bottom:20px; font-size:x-large; padding-left: 10px;">Regeneration</div>
            </div>
        </mat-card>
        <mat-card class="featured">
            <div class="title">POPULAR VIDEOS</div>
        </mat-card>
        <div fxLayout="row wrap" fxLayoutAlign="center" >
            <mat-card fxLayout="column" class="r-video-card">
                <div class="thumbnail" (click)="playVideo('0eSMstOIb-U')">
                    <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                    <img src="https://i.ytimg.com/vi/0eSMstOIb-U/hqdefault.jpg" width="100%">
                </div>
            </mat-card>
            <mat-card fxLayout="column" class="r-video-card">
                <div class="thumbnail" (click)="playVideo('yTQxF_TbVwE')">
                    <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                    <img src="https://i.ytimg.com/vi/yTQxF_TbVwE/hqdefault.jpg" width="100%">
                </div>
            </mat-card>
            <mat-card fxLayout="column" class="r-video-card">
                <div class="thumbnail" (click)="playVideo('l4TC5wl0IzE')">
                    <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                    <img src="https://i.ytimg.com/vi/l4TC5wl0IzE/hqdefault.jpg" width="100%">
                </div>
            </mat-card>
            <mat-card fxLayout="column" class="r-video-card">
                <div class="thumbnail" (click)="playVideo('SWyxZR69CI0')">
                    <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                    <img src="https://i.ytimg.com/vi/SWyxZR69CI0/hqdefault.jpg" width="100%">
                </div>
            </mat-card>
            <mat-card fxLayout="column" class="r-video-card">
                <div class="thumbnail" (click)="playVideo('ctvy3xz71OI')">
                    <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                    <img src="https://i.ytimg.com/vi/ctvy3xz71OI/hqdefault.jpg" width="100%">
                </div>
            </mat-card>
            <mat-card fxLayout="column" class="r-video-card">
                <div class="thumbnail" (click)="playVideo('nhDtUekNKEI')">
                    <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                    <img src="https://i.ytimg.com/vi/nhDtUekNKEI/hqdefault.jpg" width="100%">
                </div>
            </mat-card>
            <mat-card fxLayout="column" class="r-video-card">
                <div class="thumbnail" (click)="playVideo('KLCrAb_ESw8')">
                    <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                    <img src="https://i.ytimg.com/vi/KLCrAb_ESw8/hqdefault.jpg" width="100%">
                </div>
            </mat-card>
            <mat-card fxLayout="column" class="r-video-card">
                <div class="thumbnail" (click)="playVideo('tssvdg0e_qQ')">
                    <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                    <img src="https://i.ytimg.com/vi/tssvdg0e_qQ/hqdefault.jpg" width="100%">
                </div>
            </mat-card>
            <mat-card fxLayout="column" class="r-video-card">
                <div class="thumbnail" (click)="playVideo('3fmNHxz8Zcg')">
                    <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                    <img src="https://i.ytimg.com/vi/3fmNHxz8Zcg/hqdefault.jpg" width="100%">
                </div>
            </mat-card>
            <mat-card fxLayout="column" class="r-video-card">
                <div class="thumbnail" (click)="playVideo('oB5X9Ew760c')">
                    <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                    <img src="https://i.ytimg.com/vi/oB5X9Ew760c/hqdefault.jpg" width="100%">
                </div>
            </mat-card>
            <mat-card fxLayout="column" class="r-video-card">
                <div class="thumbnail" (click)="playVideo('XL4iCAB6MFo')">
                    <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                    <img src="https://i.ytimg.com/vi/XL4iCAB6MFo/hqdefault.jpg" width="100%">
                </div>
            </mat-card>
            <mat-card fxLayout="column" class="r-video-card">
                <div class="thumbnail" (click)="playVideo('vuMFDtpjA3M')">
                    <img src="../../assets/images/play-button2.png" class="play-button" width="100%">
                    <img src="https://i.ytimg.com/vi/vuMFDtpjA3M/hqdefault.jpg" width="100%">
                </div>
            </mat-card>
        </div>
    </div>
    <div fxFlex="30" fxLayout="column">
        <mat-card class="side-card" style="margin-bottom: 0;">
            <p><span class="title">POPULAR SEARCHES</span></p>
        </mat-card>
        <mat-card class="side-card" style="margin-top: 1px; margin-bottom: 0;">
            <mat-card-content>
                <p><a mat-list-item (click)="doSearch($event)">Robot Chicken</a></p>
                <p><a mat-list-item (click)="doSearch($event)">Filmation</a></p>
                 <p><a mat-list-item (click)="doSearch($event)">The Ready Room</a></p>
                <p><a mat-list-item (click)="doSearch($event)">Strange New Worlds</a></p>
                <p><a mat-list-item (click)="doSearch($event)">The Lower Decks</a></p>
                <p><a mat-list-item (click)="doSearch($event)">A Star Trek Fan Production</a></p>
                <p><a mat-list-item (click)="doSearch($event)">Picard</a></p>
                <p><a mat-list-item (click)="doSearch($event)">Music Video Parody</a></p>
                <p><a mat-list-item (click)="doSearch($event)">Red Shirts</a></p>
                <p><a mat-list-item (click)="doSearch($event)">Acapella</a></p>
                <p><a mat-list-item (click)="doSearch($event)">Of Gods and Men</a></p>
                <p><a mat-list-item (click)="doSearch($event)">Renegade</a></p>
                <p><a mat-list-item (click)="doSearch($event)">Star Trek vs Batman</a></p>
            </mat-card-content>
        </mat-card>
        <mat-card class="side-card" style="margin-top: 20px; margin-bottom: 0;">
            <p><span class="title">PODCASTS</span></p>
        </mat-card>
        <mat-card class="side-card" style="margin-top: 1px; margin-bottom: 0;">
            <mat-card-content>
                <p><a (click)="doSearch($event, 'UC7mxz8u6_d2bkbmZ2iWQNIQ')">Trek Cast</a></p>
            </mat-card-content>
        </mat-card>
        <mat-card class="side-card" style="margin-top: 20px; margin-bottom: 0;">
            <p><span class="title">CHANNELS</span></p>
        </mat-card>
        <mat-card class="side-card" style="margin-top: 1px; ">
            <mat-card-content>
                <p><a mat-list-item (click)="doSearch($event, 'UC0r2HSOk-1oS-BLyTfnqsSg')">Star Trek Recap</a></p>
                <p><a mat-list-item (click)="doSearch($event, 'UCy4B9pLLXFd7EdjRJDahduQ')">Power543 Fan Films</a></p>
                <p><a mat-list-item (click)="doSearch($event, 'UClDZCA_gOBNNTlle4c39Nsg')">Star Rekt</a></p>
                <p><a mat-list-item (click)="doSearch($event, 'UCWqf89_N2AT-HsyM6jIudzQ')">Avalon Universe</a></p>
                <p><a mat-list-item (click)="doSearch($event, 'PLhvh2eq-XLgqNxH6npvQxGxLCUHy90IpZ')">Star Trek Continues</a></p>
                <p><a mat-list-item (click)="doSearch($event, 'UCCkxwocDze43XGhvUptIRFQ')">Star Trek New Voyages</a></p>
                <p><a mat-list-item (click)="doSearch($event, 'UCxRrQIpejUXUi8i4mAbzSlg')">Axanar</a></p>
                <p><a mat-list-item (click)="doSearch($event, 'PLIPYu8RdPGbeTlaVl04UXjl4aLwahkPEn')">The Red Shirt Diaries</a></p>
            </mat-card-content>
        </mat-card>
        <div fxFlexOrder.xs="-1" fxLayoutAlign.xs="center">
            <div fxFlex.xs="60"  class="flip-container"  [ngClass]="{'flip': flipToggle }" >
                <div class="flipper">
                    <mat-card class="side-card front" >
                        <div (click)="flipToggle=!flipToggle" class="flipTarget">&nbsp;</div>
                        <a href="https://amzn.to/3bVkd4I" target="_blank" style="cursor:pointer;">
                            <img src="../../assets/images/amazon.png" width="100%">
                        </a>
                    </mat-card>
                    <mat-card class="side-card back" >
                        <div (click)="flipToggle=!flipToggle" class="flipTarget">&nbsp;</div>
                        <a href="https://amzn.to/3iwMX6b" target="_blank" style="cursor:pointer;">
                            <img src="../../assets/images/amazon2.png" width="100%">
                        </a>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>