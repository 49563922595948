<div class="lcars-text-bar">
    <span>SEARCH</span>
</div>

<div class="flexbox" style="align-items:center">
    <div class="col" style="margin:30px">
        <input type="text" placeholder="Enter Search Text..." [value]="searchValue" name="search"
            (keyup.enter)="doSearch(search.value)" autocomplete="off" #search style="width:100%;height:50px;padding-left:5px;background-color: black;
        border:2px solid blueviolet;margin:0;
        color:azure; font-size: 26px;
        font-family: 'Antonio', 'Arial Narrow', 'Avenir Next Condensed', sans-serif;
        line-height: 30px;">
    </div>
    <div class="button-col" style="margin:30px">
        <div class="button" id="bottom-left"><a (click)="doSearch(search.value)">SEND</a></div>
    </div>
</div>

<div class="lcars-text-bar"></div>

<div id="results">
    <div *ngFor="let video of videos; let i = index;">
        <a [href]="'/lcars/player?v='+video.videoId">
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <img [src]="'https://i.ytimg.com/vi/'+ video.videoId + '/hqdefault.jpg'" width="200px">
                <div class="title">{{video.title}}</div>
            </div>
        </a>
    <div>
    <div style="width:100%;border: 1px dashed grey;margin-bottom:5px;"></div>
</div>