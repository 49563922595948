import { Component, OnInit } from '@angular/core';
import { Video } from 'src/app/shared/models/search.interface';
import { SearchService } from 'src/app/shared/services/search.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['../../layouts/lcars/lcars.component.css']
})
export class LCARS_SearchComponent implements OnInit {
  searchValue:string = "";
  videos: Video[] = [];
  vSub: any;
  moreVideos: boolean;

  constructor(private searchService: SearchService) { }

  ngOnInit(): void {
  }

  doSearch(val){
    if(val){
      this.getVideos(val);
    }
  }

  getVideos(search: string, videoOptions?: string) {
    this.vSub = this.searchService.getVideos(search, videoOptions)
      .subscribe((items: any) => {
        this.moreVideos = !!this.searchService.nextPageToken;
        items.forEach(item => {
          let pushVideo = false;
          if (item.snippet.description.toLowerCase().includes('star trek') || 
              item.snippet.title.toLowerCase().includes('star trek')) {
              pushVideo = true;
          }
          if (pushVideo) {
            this.videos.push(
              {
                title: item.snippet.title,
                videoId: item.id,
                videoUrl: `https://www.youtube.com/watch?v=${item.id.videoId}`,
                channelId: item.snippet.channelId,
                channelUrl: `https://www.youtube.com/channel/${item.snippet.channelId}`,
                channelTitle: item.snippet.channelTitle,
                description: item.snippet.description,
                publishedAt: new Date(item.snippet.publishedAt),
                thumbnail: item.snippet.thumbnails.high.url,
                views: item.statistics.viewCount
              }
            );
          }
        });
        // this.inputTouched = true;
        // this.loading = false;
      });
  }

}
